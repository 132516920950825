import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { ButtonBase } from '@material-ui/core';
import { InputAdornment } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  PhonesComponent,
  CheckboxesComponent,
  DatePickerComponent,
} from "../../../../../Components";
import { ActivityFilesUploader  } from '../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader' ;
import { UploadDialog } from '../../../FormBuilder/Dialogs/UploadDialog' ; 

import {
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../Helper";
import { TableActions } from '../../../../../Enums';
import { AgreementDetailsTable } from './AgreementDetailsTable' ; 
import { AgreementDetailsManagementDialog } from './AgreementDetailsManagementDialog' ;



import {  GetLookupItemsByLookupTypeName} from '../../../../../Services' ; 

export const AgreementDetails = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
   const  [isLoading , setIsLoading] = useState({allAgreementTypes : false , save : false  , info : false}) ;
   const [isOpenEditDialog ,  setIsOpenEditDialog] = useState(false);
   const [openUploadDialog , setOpenUploadDialog ] = useState(false) ; 

  const [items , setItems] = useState({
    allAgreementTypes :[] 
  })  ; 

  const [defaultSelected, setDefaultSelected] = useState({
    agreementType: null,
    agreementStartDate: null,
    agreementDurationMonths: null,
    agreementEndDate: null,
    projectName: null,
    signingBranch: null,
    authorizedSignatory: null,
    PSICommission: null,
    PSICommissionSlabs: { selected : []},
    marketingBudgetAllocation: null,
    budgetAmount: null,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultSelected,
  });

  const getLookupItemsByName = async (lookUpName , key ) => {

    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(result && result.status && result.status !== 200)) {
        setItems((item) => ({ ...item, [key]: (result && result.result) || []   }));
    } 
    else  setItems((item) => ({ ...item, [key]: []}));
    setIsLoading((item) => ({ ...item, [key]: false }));

  };

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item">
              <AutocompleteComponent
                key={`agreementType`}
                selectedValues={state.agreementType}
                idRef={`agreementTypeId`}
                multiple={false}
                inputPlaceholder={t("AgreementType")}
                data={items &&  items.allAgreementTypes}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.allAgreementTypes}
                withLoader
                withoutSearchButton
                labelValue={"agreement-type"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.allAgreementTypes?.length === 0 )
                  getLookupItemsByName('AgreementType' , 'allAgreementTypes');
                }}
              />
            </div>

            <div className="px-2 item">
              <DatePickerComponent
                idRef={"agreementStartDateRef"}
                format="YYYY/MM/DD"
                labelValue={"agreement-start-date"}
                 value={(state && state.agreementStartDate && moment(state.agreementStartDate).isValid() &&  moment(state.agreementStartDate).format('YYYY/MM/DD'))}
                onDateChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item mt-1">
              <Inputs
                isAttachedInput
                idRef={"agreementDurationMonthsRef"}
                labelValue={"agreement-duration-months"}
                type={"number"}
                withNumberFormat
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <DatePickerComponent
                idRef={"AgreementEndDateRef"}
                format="YYYY/MM/DD"
                labelValue={"agreement-end-date"}
                onDateChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item mt-1">
              <Inputs
                isAttachedInput
                idRef={"projectNameRef"}
                labelValue={"project-name"}
                type={"number"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <AutocompleteComponent
                key={`signingBranch`}
                idRef={`signingBranchRef`}
                multiple={false}
                inputPlaceholder={t("signing-branch")}
                data={[]}
                //inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && t(`${translationPath}${option.name}`)) || ""}
                onChange={(e, v) => {}}
                onInputKeyUp={(e) => {}}
                // isLoading={isLoading}
                // withLoader
                withoutSearchButton
                labelValue={"signing-branch"}
                filterOptions={(options) => options}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>

            <div className="px-2 item mt-1">
              <Inputs
                idRef={`authorizedSignatoryRef`}
                labelClasses=""
                key={`authorizedSignatory`}
                labelValue={"authorized-signatory"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item mt-1">
              <Inputs
                idRef={`psiCommissionRef`}
                labelClasses=""
                key={`PSI-commission`}
                labelValue={"psi-commission"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              {/* <AutocompleteComponent
                key={`PSI-commission-slabs`}
                idRef={`PSICommissionSlabsRef`}
                multiple={false}
                inputPlaceholder={t("PSI-commission-slabs")}
                data={[{ key : 'yes' , name : 'yes'} , { key : 'no' , name : 'no'}]}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && t(`${translationPath}${option.name}`)) || ""}
                onChange={(e, v) => {}}
                withoutSearchButton
                labelValue={"PSI-commission-slabs"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              /> */}
                 <AutocompleteComponent
            idRef={'PSICommissionSlabsRef'}
            // labelClasses={isRequired ? "c-red" : ""}
            multiple={false}
            selectedValues={ null 
            //   item.data.multi && item.data.multi === "true"
            //     ? (itemValue && [...itemValue.selected]) || []
            //     : itemValue || null
             }
            defaultValue={null}
            data={ []}
            displayLabel={(option) =>
              ( option.lookupItemName)
            }
            // chipsLabel={(option) =>
            //   !item.data.lookup ? option : option.lookupItemName
            // }
            // getOptionSelected={(option) =>
            // }
            onChange={(e, v) => {
             
            }}
            withoutSearchButton
            labelValue={t('PSI-commission-slabs')}
            buttonOptions={{
              className: "btns-icon theme-solid bg-blue-lighter",
              iconClasses:
                '' === "external_url" ? "mdi mdi-plus" : "mdi mdi-attachment",
              isDisabled:  state?.PSICommissionSlabs?.selected?.length  === 0  , 
              isRequired: false,
              onActionClicked: () => {
                setOpenUploadDialog(true);

              },
            }}
          />
          {state?.PSICommissionSlabs && state.PSICommissionSlabs.selected && state.PSICommissionSlabs.selected.length > 0 && (
        <UploadDialog
          open={openUploadDialog}
          onChange={(e) => {
           // onValueChanged({ ...e, selected: itemValue && itemValue.selected });
          }}
          initialState={state.PSICommissionSlabs}
          closeDialog={() => {
            setOpenUploadDialog(false);
          }}
        />
      )}
            </div>
            <div className="px-2 item">
              <AutocompleteComponent
                key={`marketingBudgetAllocation`}
                idRef={`marketingBudgetAllocationRef`}
                multiple={false}
                inputPlaceholder={t("marketing-budget-allocation")}
                data={[{ key : 'yes' , name : 'yes'} , { key : 'no' , name : 'no'}]}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && t(`${translationPath}${option.name}`)) || ""}
                onChange={(e, v) => {}}
                withoutSearchButton
                labelValue={"marketing-budget-allocation"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                idRef={`budget-amount`}
                labelClasses=""
                key={`budget-amount`}
                labelValue={"budget-amount"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className='px-2 w-100 mt-2'>
             <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              multiple={true}
              accept={'/*'}
           />
    
            </div>
            <div className="mt-3 addBtn">
            <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              //saveAgreementDetails() 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-agreement-details`)}
          </ButtonBase>
          <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              //saveAgreementDetails() 
              setIsOpenEditDialog(true) ; 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}edit-agreement-details`)}
          </ButtonBase>
          </div>
          </div>
        </div>
      </div>
      <AgreementDetailsTable 
       translationPath={translationPath}
       parentTranslationPath={parentTranslationPath}
      />
      
        {
          isOpenEditDialog && (
            <AgreementDetailsManagementDialog
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isOpen ={isOpenEditDialog}
            onClose={()=> setIsOpenEditDialog(false)}
            onSave={()=> setIsOpenEditDialog(false)}
            />
          )
        
        }
    </div>
  );
};
