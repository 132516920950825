import React, { useState, useRef, useContext } from "react";
import clsx from "clsx";
import { Paper, Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useSelectedTheme, useOutsideClick } from "../../../Hooks";
import { CustomInput, CustomButton } from "..";
import ReactDOM from "react-dom";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import {
  CreateUserColumnGroup,
  GetAllUserColumnGroup,
  UpdateUserColumnGroups,
} from "../../../Services";
import { CloseXIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const SaveFilterModal = () => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { filterModalData, setFilterModalData } =
    useContext(ContactLayoutContext);
  const { setAlertBoxContent } = useVerticalNav();

  const [selectedValue, setSelectedValue] = useState("");
  const [filterText, setFilterText] = useState("");
  const modalRef = useRef(null);
  const iconRef = useRef(null);

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const saveData = async () => {
    const body = {
      fieldsGroupName: filterText?.trim() || "",
      fieldsGroupData: JSON.stringify(filterModalData?.selectedColumns),
      searchFiledsType: 1,
    };

    const getData = async () => {
      try {
        const res = await GetAllUserColumnGroup();

        setFilterModalData((prevData) => ({
          ...prevData,
          data: res,
        }));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    try {
      let result;

      if (filterText) {
        // add new view
        result = await CreateUserColumnGroup(body);
      } else {
        //update existing view
        const view = filterModalData?.data?.find(
          (el) => el?.fieldsGroupName === selectedValue
        );

        const payload = [
          {
            userFilterGroupId: view.id,
            fieldsGroupName: view.fieldsGroupName,
            fieldsGroupData: JSON.stringify(filterModalData?.selectedColumns),
            searchFiledsType: 1,
          },
        ];

        result = await UpdateUserColumnGroups(payload);
      }

      if (!(result && result?.status && result?.status !== 200)) {
        getData();

        setAlertBoxContent({
          display: true,
          variant: "success",
          title: `New view: "${filterText?.trim()}" was created successfully.`,
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: "Failed to add view",
          description: "View was not saved. Please try again.",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } catch (error) {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to add view",
        description: "View was not saved. Please try again.",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      console.error("Error:", error);
    }
  };

  const handleSave = async () => {
    if (filterModalData?.selectedColumns?.length > 0) {
      await saveData();
    }

    onClose();
  };

  const onClose = () => {
    setFilterModalData((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  useOutsideClick({
    ref: modalRef,
    handler: () => onClose(),
    iconRef,
  });

  return ReactDOM.createPortal(
    <Box className={styles.modalContainer}>
      <Paper ref={modalRef} className={clsx(styles.modalPaper)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span className={styles.text}>Add New View</span>
          <CloseXIcon
            onClick={onClose}
            style={{ cursor: "pointer" }}
            width="20"
            height="20"
            fill={palette.foreground.quinary}
          />
        </Box>
        <Box>
          <Autocomplete
            classes={{ root: styles.inputField }}
            id="add-view"
            freeSolo
            options={
              filterModalData?.data?.map((option) => option?.fieldsGroupName) ||
              []
            }
            onChange={(event, newValue) => {
              setSelectedValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                placeholder="Update an existing view or add a new name"
                onChange={handleInputChange}
                {...params}
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Box>
        <Box className={styles.actions}>
          <CustomButton
            boxShadow="xs"
            size="md"
            onClick={onClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </CustomButton>

          <CustomButton
            boxShadow="xs"
            size="md"
            onClick={handleSave}
            variant="contained"
            disabled={!(filterText || selectedValue)}
          >
            {selectedValue ? "Update" : "Save"}
          </CustomButton>
        </Box>
      </Paper>
    </Box>,
    document.getElementById("portal-root")
  );
};

export default SaveFilterModal;
