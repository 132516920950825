import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "24px",

      [theme.breakpoints.down('md')]: {
        flexDirection: "column-reverse",
        gap: "8px",
      },
    },
    contentWrapper: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      [theme.breakpoints.down('md')]: {
        gap: "4px",
      },
    },
    typography: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.foreground.secondary,
    },
    select: {
      color: theme.palette.text.secondary,
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 600,
      width: "92px",
      height: "40px",
      padding: "8px 12px",
      borderRadius: "8px",
      border: `1px solid ${theme.palette.border.main}`,
      background: "transparent",
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

      [theme.breakpoints.down('md')]: {
        padding: "2px 4px",
        width: "73px",
      },
    },
    dropdownIcon: {
      cursor: "pointer",
      color: theme.palette.foreground.quarterary,
      fontSize: "20px",
    },
    buttonGroup: {
      '& .MuiButtonBase-root': {
        backgroundColor: theme.palette.background.primary,
        borderColor: `${theme.palette.border.primary} !important`,
        fontSize: "14px",
        fontWeight: 600,
        color: theme.palette.text.secondary,

        [theme.breakpoints.down('sm')]: {
          paddingRight: "0px",
          paddingLeft: "0px",
        },

        '& .MuiButton-label': {
          gap: "8px",
        },

        '&:hover': {
          backgroundColor: `${theme.palette.background.primary_hover} !important`,
        },
      },
      '& .Mui-disabled': {
        color: `${theme.palette.text.secondary_disabled}`,
      },

      '& .MuiButtonBase-root:nth-of-type(1)': {
        ...(isAr ? {
            borderRadius: "0 8px 8px 0",
            marginLeft: "-1px",  
        } : {
          borderRadius: "8px 0 0 8px",
        }),
      },

      '& .MuiButtonBase-root:nth-last-of-type(1)': {
        ...(isAr ? { borderRadius: "8px 0 0 8px" } : {
          borderRadius: "0 8px 8px 0",
        }),
      },

      [theme.breakpoints.down('375')]: {
        '& .MuiButtonBase-root:nth-of-type(1), & .MuiButtonBase-root:nth-of-type(2)': {
          minWidth: "30px",
        },
  
        '& .MuiButtonBase-root:nth-last-of-type(1), & .MuiButtonBase-root:nth-last-of-type(2)': {
          minWidth: "30px",
        }
      },
    },
    activePageButton: {
      fontWeight: "bold",
      color: theme.palette.primary.main,

      '& input': {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: "4px",
        paddingInlineStart: "4px",
        background: theme.palette.background.primary,
        maxWidth: "80px",
        [theme.breakpoints.down('md')]: {
          maxWidth: "60px",
        },
      },

      [theme.breakpoints.down('sm')]: {
        paddingRight: "1px !important",
        paddingLeft: "1px !important",
      },
    },
    disabledButton: {
      color: `${theme.palette.text.secondary_disabled} !important`,
      '& svg, & svg path': {
        fill: `${theme.palette.text.secondary_disabled} !important`,
      },
      borderColor: `${theme.palette.border.primary} !important`,
      cursor: "not-allowed !important",
    },
  };
});
