import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import Joi from "joi";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { MobileNumberComponent  , EmailComponent   } from './Component/FieldsComponent';
import {
  Spinner,
  Inputs,
  AutocompleteComponent,
  DatePickerComponent,
} from "../../../../../../Components";
import { showSuccess, showError } from "../../../../../../Helper";

import { ActivityFilesUploader } from "../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";

export const DeveloperEmployeesManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  isLoading,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const [defaultSelected, setDefaultSelected] = useState({
    employeeName : null , 
    jobTitle : null , 

   
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultSelected,
  });

  const schema = Joi.object({
    employeeName: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}developer-Employees-name-is-required`),
        "string.empty": t(`${translationPath}developer-Employees-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const saveHandler = async () => {
    setIsSubmitted(true);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <Spinner isActive={isLoading || saveIsLoading} isAbsolute />
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-developer-employee`)}</span>
        </DialogTitle>
        <DialogContent>
          <div className="dialog-content-wrapper">
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"employeeNameRef"}
                labelValue={"employee-name"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item w-50">
            <Inputs
                isAttachedInput
                idRef={"jobTitleRef"}
                labelValue={"job-title"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">

                <MobileNumberComponent
                  mobileNumbers={''}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  setNumber={(value) => {
                   // setState && setState({ id: 'phoneNumber', value: event || null });
              }}
        />
            </div>
            <div className="dialog-content-item">
            <EmailComponent
             emailAddresses={state.emailAddresses}
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             setEmail={(value) => {
           // onStateChangeHandler('emailAddresses', value || null);
            // setState({ id: 'emailAddress', value: event || null });
          }}
        />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"linkedInProfileRef"}
                labelValue={"linkedIn-profile"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="mt-2 dialog-content-item w-100">
              <ActivityFilesUploader
                state={state}
                setState={setState}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                multiple={true}
                accept={"/*"}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="form-builder-wrapper">
            <div className="form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between">
              <div className="MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing">
                <div className="save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2">
                  <div className="cancel-wrapper d-inline-flex-center">
                    <ButtonBase
                      className="MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary"
                      tabIndex="0"
                      id="cancel-ref-action"
                      onClick={() => onClose()}
                    >
                      <span className="MuiButton-label">
                        <span>{t(`${translationPath}cancel`)}</span>
                      </span>
                      <span className="MuiTouchRipple-root" />
                    </ButtonBase>
                  </div>
                  <div className="save-wrapper d-inline-flex-center">
                    <ButtonBase
                      className="MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary w-100 mx-2 mb-2"
                      tabIndex="0"
                      onClick={() => {
                        onSave();
                      }}
                    >
                      <span className="MuiButton-label">
                        <span>{t(`${translationPath}save`)}</span>
                      </span>
                      <span className="MuiTouchRipple-root" />
                    </ButtonBase>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeveloperEmployeesManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPortalConfiguration: PropTypes.instanceOf(Object),
  isLoading: PropTypes.instanceOf(Object),
};
DeveloperEmployeesManagementDialog.defaultProps = {
  editPortalConfiguration: undefined,
  isLoading: undefined,
};
