import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useReducer,
  } from "react";
  import { useTranslation } from "react-i18next";
  import {
    Tables
  } from "../../../../../Components";
  import {
    GetParams,
    showError,
    showSuccess,
    showWarn,
  } from "../../../../../Helper";
  import { TableActions } from '../../../../../Enums'; 
  import { MessageConfirmDialog } from "../../../../../SharedComponents";


  
  export const SocialMediaLinkComponentTable = ({
    translationPath,
    parentTranslationPath,
  }) => {
    const { t } = useTranslation(parentTranslationPath, "Shared");  
    const [allSocialMediaLink, setAllSocialMediaLink ] = useState({ result :  [] , totalCount : 0  }) ; 
    const [filter , setFilter] = useState({pageIndex  : 0 , pageSize :  25 ,  search :''});
    const [ activeItem ,  setActiveItem ] = useState(null) ; 
    const [isOpenConfirmDialog , setIsOpenConfirmDialog ] = useState(false);
    const [isMissingInfoMSGOpen , setIsMissingInfoMSGOpen] = useState(false) ; 


    const DeleteHandler = useCallback(async () => {  
        // const res = await DeleteGeneralPropertyCriteria(
        //   activeItem && activeItem.propertyRatingGeneralPropertyCriteriaScoreId
        // );
        // if (res && res.ErrorId) {
        //   showError(t(`${translationPath}failed-to-delete-property-age`));
        // } else if (!(res && res.status && res.status !== 200)) {
        //   setIsMissingInfoMSGOpen(false) ; 
        //   showSuccess(t(`${translationPath}property-age-deleted-sucessfuully`));
       
        // } else showError(t(`${translationPath}failed-to-delete-property-age`));
      },[activeItem]);


    const tableActionClicked = useCallback(
        (actionEnum, item, focusedRow, event) => {
          event.stopPropagation();
          event.preventDefault();
          setActiveItem(item);
          if (actionEnum === TableActions.deleteText.key) {
            setIsMissingInfoMSGOpen(true);
          } else if (actionEnum === TableActions.editText.key)
            {
            
           }
        },
        []
      );

      const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
      };
      const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
      };


  
    return (
      <div className="d-flex-column mt-2">
        <Tables
            data={allSocialMediaLink ? allSocialMediaLink.result : []}
            headerData={[
              {
                id: 1,
                label: t(`${translationPath}linkedIn-link`),
                input: 'linkedInLink',
              },
              {
                id: 2,
                label: t(`${translationPath}facebook-link`),
                input: 'facebook-link',

                
              },
              {
                id: 3,
                label: t(`${translationPath}instagram-account-number`),
                input: 'instagram-account-number',

                
              },
              {
                id: 4,
                label: t(`${translationPath}snapchat-link`),
                input: 'snapchat-link',

              },
              {
                id: 5,
                label: t(`${translationPath}tiktok-link`),
                input: 'tiktok-link',
              },
              {
                id: 6,
                label: t(`${translationPath}youTube-link`),
                input: 'youTube-link',

              },
              {
                id: 7,
                label: t(`${translationPath}X`),
                input: 'X',

              },
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            defaultActions={[
              {
                enum: TableActions.editText.key,
                isDisabled: false,
                externalComponent: null,
              },
              {
                enum: TableActions.deleteText.key,
                isDisabled: false,
                externalComponent: null,
              },
            ]}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            itemsPerPage={filter.pageSize}
            totalItems={allSocialMediaLink ? allSocialMediaLink.totalCount : 0}
          />
        {isMissingInfoMSGOpen  && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-social-media-link",
            body :(t(`${translationPath}are-you-sure-you-want-to-delete-social-media-link`) + ' ' + ((activeItem && activeItem.lookupItemName && t(`${translationPath}`)) || '') + `  ${(activeItem && activeItem.lookupItemName)||''}`)

          }}
          confirmButton={{
            label: (t(`${translationPath}confirm`)) , 
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label  :(t(`${translationPath}discard`)),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      </div>
    );
  };
  