

import React, { useState ,  useEffect , useReducer  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
  DialogActions, DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import {
  Spinner, 
} from '../../../../../../Components';
import { showSuccess ,  showError } from '../../../../../../Helper' ; 
import { AgreementDetailtsFooterDialog  , AgreementDetailsContentDialog   } from '../AgreementDetailsManagementDialog' ; 




export const AgreementDetailsManagementDialog = ({
   parentTranslationPath ,
   translationPath ,
   isOpen ,
   onClose ,
   onSave  , 
   isLoading ,   
  }) => {

  const { t } = useTranslation(parentTranslationPath);

  const [isSubmitted , setIsSubmitted] = useState(false) ;
  const [activeItem , setActiveItem] = useState(null) ; 
  const [saveIsLoading, setSaveIsLoading] = useState(false) ;

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  }
  const [defaultSelected, setDefaultSelected] = useState({
    agreementType: null,
    agreementStartDate: null,
    agreementDurationMonths: null,
    agreementEndDate: null,
    projectName: null,
    signingBranch: null,
    authorizedSignatory: null,
    PSICommission: null,
    PSICommissionSlabs: null,
    marketingBudgetAllocation: null,
    budgetAmount: null,
  });


  const [state, setState] = useReducer(reducer, {
    ...defaultSelected,
  });

  const schema = Joi.object({
    agreementType: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}agreement-type-is-required`),
        'string.empty': t(`${translationPath}agreement-type-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);


  const saveHandler = async () => 
    {
    setIsSubmitted(true);
   
  };


  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className='activities-management-dialog-wrapper'
        maxWidth='sm' 
        disableBackdropClick
      >
      <Spinner isActive={(isLoading || saveIsLoading )} isAbsolute />
          <DialogTitle id='alert-dialog-slide-title'>
            <span>
                {t(`${translationPath}edit-agreement-details`)}
            </span>
          </DialogTitle>
          <DialogContent>
             <AgreementDetailsContentDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state} 
              setState={setState}
              schema={schema}
              isSubmitted={isSubmitted}
              activeItem={activeItem}
            /> 
          </DialogContent>
          <DialogActions>
            <AgreementDetailtsFooterDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onClose={onClose}
              onSave ={saveHandler}
            />  
          </DialogActions>
      </Dialog>
    </div>
  );

};

AgreementDetailsManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPortalConfiguration: PropTypes.instanceOf(Object),
  isLoading : PropTypes.instanceOf(Object), 
};
AgreementDetailsManagementDialog.defaultProps = {
  editPortalConfiguration: undefined ,
  isLoading : undefined , 

};

