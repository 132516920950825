import { AgreementDetails , AuditAndCompliance  , DeveloperDocumentsComponent   } from '../AgreementsAndCompliance' ; 
export const AgreementsAndComplianceVerticalTabsData = [
 {
   label: 'agreement-details',
   component: AgreementDetails ,
 },
 {
   label: 'audit-and-compliance',
   component: AuditAndCompliance ,

 },
 
 {
   label: 'documents' , 
   component: DeveloperDocumentsComponent ,
     
 },
 
] ;



