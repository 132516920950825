import React, { useEffect, useRef, useState } from "react";
import { Paper, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import { uploadFile, importFile } from "../../../Services";
import { CustomButton } from "../CustomButton";
import {
  useSelectedTheme,
  useOutsideClick,
  useTranslate,
} from "../../../Hooks";
import {
  CloseXIcon,
  UploadCloudIcon,
  GetHelpIcon,
} from "../../../assets/icons";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// Styles
import useStyles from "./styles";

const ImportContactModal = ({ onClose }) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { isDarkMode } = useSelector((state) => state.theme);
  const { translate } = useTranslate("NewContactsView");
  const { setAlertBoxContent } = useVerticalNav();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const modalRef = useRef(null);
  const iconRef = useRef(null);
  const [activeCard, setActiveCard] = useState("individual");
  const [uploadedFileId, setUploadedFileId] = useState(null);

  useOutsideClick({
    ref: modalRef,
    handler: () => onClose,
    iconRef,
  });

  const handleCardClick = (cardType) => {
    setActiveCard(cardType);
  };

  const uploadHandler = () => {
    if (acceptedFiles[0]) {
      uploadFile({ file: acceptedFiles[0] })
        .then((response) => {
          setUploadedFileId(response?.uuid);
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  };

  const onSubmit = async () => {
    if (uploadedFileId) {
      const response = await importFile({
        fileId: uploadedFileId,
        importProcceseType: 1,
      });

      onClose();
      if (response) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: `Contact file sent successfully.`,
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: "Contact file sending failed",
          description: "Failed to import contact file. Please try again.",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    }
  };

  useEffect(() => {
    uploadHandler();
  }, [acceptedFiles]);

  return (
    <Box className={styles.modalContainer}>
      <Paper ref={modalRef} className={styles.modalPaper}>
        <Box className={styles.content}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span className={styles.text}>{translate("import-contacts")}</span>

            <CloseXIcon
              onClick={onClose}
              style={{ cursor: "pointer" }}
              width="20"
              height="20"
              fill={palette.foreground.quinary}
            />
          </Box>
        </Box>
        <Box className={styles.contactTypeContainer}>
          <Box className={styles.contactType}>
            <span>{translate("contacts-type")}</span>

            <Box
              className={`${styles.card} ${
                activeCard === "individual" ? styles.activeCard : ""
              }`}
              onClick={() => handleCardClick("individual")}
            >
              <img
                src={`/images/contacts/individualType${
                  isDarkMode ? "Dark" : "Light"
                }Icon.svg`}
                alt="IndividualTypeIcon"
              />

              <span>{translate("individualText")}</span>
            </Box>

            <Box
              className={`${styles.card} ${
                activeCard === "corporate" ? styles.activeCard : ""
              }`}
              onClick={() => handleCardClick("corporate")}
            >
              <img
                src={`/images/contacts/corporateType${
                  isDarkMode ? "Dark" : "Light"
                }Icon.svg`}
                alt="CorporateTypeIcon"
              />

              <span> {translate("corporateText")}</span>
            </Box>
          </Box>

          <Box className={styles.uploadSection}>
            <Box className={styles.uploadInstructions}>
              <Box className={styles.header}>
                <span> {translate("upload-file")}</span>
                <GetHelpIcon
                  width="16"
                  height="16"
                  fill={palette.foreground.quinary}
                  style={{ marginLeft: "2px" }}
                />
              </Box>
              <p>
                {translate("heres-an-example-from-the-excel-file-that-you-can")}{" "}
                <a
                  className={styles.downloadLink}
                  href={
                    activeCard === "individual"
                      ? "/files/IndividualContact.xlsx"
                      : activeCard === "corporate"
                      ? "/files/CompanyContact.xlsx"
                      : "#"
                  }
                  download={
                    activeCard === "individual"
                      ? "Individual Contact"
                      : activeCard === "corporate"
                      ? "Company Contact"
                      : undefined
                  }
                  style={{
                    pointerEvents: activeCard ? "auto" : "none",
                    color: activeCard
                      ? palette.button.tertiary_fg
                      : palette.text.disabled,
                  }}
                >
                  {translate("download")}
                </a>
              </p>
              <p> {translate("add-data-to-it-and-then-upload-it-again")}</p>
            </Box>

            <Box className={styles.dropzoneContainer}>
              <Box {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps({ accept: ".xls,.xlsx,.xlsm" })} />
                <UploadCloudIcon
                  onClick={onClose}
                  style={{ marginBottom: "10px" }}
                  width="20"
                  height="20"
                  fill={palette.button.secondary_fg}
                />
                <Box>
                  <span className={styles.clickText}>
                    {translate("click-to-upload")}&nbsp;
                  </span>
                  <span className={styles.dragText}>
                    {" "}
                    {translate("or-drag-and-drop")}
                  </span>
                </Box>
                {translate(
                  "you-can-upload-an-excel-file-to-load-a-set-of-leads"
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.actions}>
          <CustomButton
            boxShadow="xs"
            size="md"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </CustomButton>
          <CustomButton
            boxShadow="xs"
            size="md"
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={!activeCard || acceptedFiles?.length === 0}
          >
            Submit
          </CustomButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default ImportContactModal;
