import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { ButtonBase } from '@material-ui/core';
import { InputAdornment } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  PhonesComponent,
  CheckboxesComponent,
  DatePickerComponent,
} from "../../../../../Components";
import {ActivityFilesUploader  } from '../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader' ;
import {
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../Helper";
import { TableActions } from '../../../../../Enums';
import { EmailComponent , MobileNumberComponent } from './DeveloperEmployeesComponents/Component/FieldsComponent' ; 
 import { DeveloperEmployeesTable , DeveloperEmployeesManagementDialog } from './DeveloperEmployeesComponents' ; 


import {  GetLookupItemsByLookupTypeName} from '../../../../../Services' ; 

export const DeveloperEmployees = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
   const  [isLoading , setIsLoading] = useState({allAgreementTypes : false , save : false  , info : false}) ;
   const [isOpenEditDialog ,  setIsOpenEditDialog] = useState(false);

  const [items , setItems] = useState({
    allAgreementTypes :[] 
  })  ; 

  const [defaultSelected, setDefaultSelected] = useState({
    employeeName :  null , 
    jobTitle : null , 
    emailAddress : null ,  
    mobileNumber : null   , 
    linkedInProfile  : null , 
    employeePhoto : null 
   
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultSelected,
  });

  const getLookupItemsByName = async (lookUpName , key ) => {

    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(result && result.status && result.status !== 200)) {
        setItems((item) => ({ ...item, [key]: (result && result.result) || []   }));
    } 
    else  setItems((item) => ({ ...item, [key]: []}));
    setIsLoading((item) => ({ ...item, [key]: false }));

  };

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"EmployeeNameRef"}
                labelValue={"employee-name"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"JobTitleRef"}
                labelValue={"job-title"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
            />
            </div>
            <div className="px-2 item mt-1">
            <EmailComponent
             emailAddresses={state.emailAddresses}
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             setEmail={(value) => {
           // onStateChangeHandler('emailAddresses', value || null);
            // setState({ id: 'emailAddress', value: event || null });
          }}
        />
            </div>
            <div className="px-2 item mt-1">
            <MobileNumberComponent
                  mobileNumbers={''}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  setNumber={(value) => {
                   // setState && setState({ id: 'phoneNumber', value: event || null });
              }}
        />
             </div>
             <div className="px-2 item mt-1">
             <Inputs
                isAttachedInput
                idRef={"linkedInProfileRef"}
                labelValue={"linkedIn-profile"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
             </div>
            <div className='px-2 mt-1 w-100'>
             <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              multiple={true}
              accept={'/*'}
           />
    
            </div>
            <div className="mt-3 addBtn">
            <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              //saveAgreementDetails() 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-developer-employee`)}
          </ButtonBase>
          <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              setIsOpenEditDialog(true) ; 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}edit-developer-employee`)}
          </ButtonBase>
          </div>
          </div>
        </div>
      </div>
       <DeveloperEmployeesTable 
         translationPath={translationPath}
         parentTranslationPath={parentTranslationPath}
      />
      
        {
          isOpenEditDialog && (
            <DeveloperEmployeesManagementDialog
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isOpen ={isOpenEditDialog}
            onClose={()=> setIsOpenEditDialog(false)}
            onSave={()=> setIsOpenEditDialog(false)}
            />
          )
        
        }
    </div>
  );
};
