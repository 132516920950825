
import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { Button } from 'react-bootstrap';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import {
  Spinner,
  Inputs,
} from "../../../../../Components";
import { showSuccess, showError } from "../../../../../Helper";
import { getIsValidURL,isURL, matchYoutubeUrl , matchFacebookUrl  , matchInstagramUrl , matchLinkedinUrl , matchSnapchatUrl , matchTiktokLink  } from '../../../../../Helper/Link.helper';
import { GetLookupItemsByLookupTypeName } from '../../../../../Services' ; 
import { FooterWrapperComponent } from '../../DevelopersUtilities/Components' ; 


export const SocialMediaLinkComponent = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,

}) => {
  const { t } = useTranslation(parentTranslationPath);
  const  [isLoading , setIsLoading] = useState({save : false  , info : false}) ;
  const [items , setItems] = useState({
   countries :[]  , 
   cities : [] , 
   districts : [] , 
   communities : [] ,
   subCommunities : []

 })  ; 


 const [defaultSelected, setDefaultSelected] = useState({
  linkedInLink :  null , 
  facebookLink : null , 
  instagramAccountNumber : null  , 
  snapchatLink : null , 
  tiktokLink: null , 
  youTubeLink  : null  , 
  X : null , 

 
});

const reducer = (select, action) => {
  if (action.id !== "edit") return { ...select, [action.id]: action.value };
  if (action.id === "edit") {
    return {
      ...action.value,
    };
  }
};

const [state, setState] = useReducer(reducer, {
  ...defaultSelected,
});


  const schema = Joi.object({
    employeeName: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}developer-Employees-name-is-required`),
        "string.empty": t(`${translationPath}developer-Employees-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const saveHandler = async () => 
  {

  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <Spinner isActive={isLoading.info} isAbsolute />
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-social-media-link`)}</span>
        </DialogTitle>
        <DialogContent>
        <div className="dialog-content-wrapper">
         <div className="dialog-content-item">
              <Inputs
                  isAttachedInput
                  idRef={"LinkedInLinkRef"}
                  labelValue={"linkedIn-link"}
                  type={"text"}
                  value= { state.linkedInLink }
                  onInputChanged={(e) => 
                    {
                      setState({ id :  'linkedInLink', value: e.target.value });
  
                    }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchLinkedinUrl(state.linkedInLink|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.linkedInLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
                />
              </div>
              
              <div className="dialog-content-item">
              <Inputs
                  isAttachedInput
                  idRef={"Facebook LinkRef"}
                  labelValue={"facebook-link"}
                  type={"text"}
                  value= {state.facebookLink }
                  onInputChanged={(e) => 
                    {
                      setState({ id :  'facebookLink', value: e.target.value });

                    }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchFacebookUrl(state.facebookLink|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.facebookLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
    
              />
              </div>
               <div className="dialog-content-item">
               <Inputs
                  isAttachedInput
                  idRef={"InstagramAccountNumberRef"}
                  labelValue={"instagram-account-number"}
                  type={"text"}
                  value= { state.instagramAccountNumber }
                  onInputChanged={(e) => {}}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
    
                />
               </div>
               <div className="dialog-content-item">
               <Inputs
                  isAttachedInput
                  idRef={"SnapchatLinkRef"}
                  labelValue={"snapchat-link"}
                  type={"text"}
                  value= { state.snapchatLink }
                  onInputChanged={(e) => {
                    setState({ id :  'snapchatLink', value: e.target.value });

                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchSnapchatUrl(state.snapchatLink|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.snapchatLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
    
                />
               </div>
               <div className="dialog-content-item">
               <Inputs
                  isAttachedInput
                  value= { state.tiktokLink }
                  idRef={"TikTokLinkRef"}
                  labelValue={"tiktok-link"}
                  type={"text"}
                  onInputChanged={(e) => {
                    setState({ id :  'tiktokLink', value: e.target.value });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchTiktokLink(state.tiktokLink|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.tiktokLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
    
                />
               </div>
               <div className="dialog-content-item">
               <Inputs
              idRef='YouTubeLinkRef'
              labelValue='youTube-link'
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value= {state.youtubeLink}
              onInputChanged={(e) => 
                {
                  setState({ id :  'youtubeLink', value: e.target.value });
                }}
              endAdornment={(
                <>
                  <Button
                    disabled={!(matchYoutubeUrl(state.youtubeLink || ''))}
                    onClick={() => {
                      try {
                        window.open(state.youtubeLink || '');
                      } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                    }}
                    className='googlemaps-bbt '
                  >
                    <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                  </Button>
                </>
              )}
            />

               </div>
               <div className="dialog-content-item">
               <Inputs
                  isAttachedInput
                  value= {state.X }
                  idRef={"XRef"}
                  labelValue={"X"}
                  type={"text"}
                  onInputChanged={(e) => {
                    setState({ id :  'X', value: e.target.value });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(getIsValidURL(state.X|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.X || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
    
                />
               </div>
               </div>
            
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
             parentTranslationPath={parentTranslationPath} 
             translationPath={translationPath}
             onClose={onClose} 
             onSave={onSave}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};


