import { DeveloperEmployees ,  BranchesLocation } from '../PeopleAndLocations' ; 
export const PeopleAndLocationsVerticalTabsData = [
 {
   label: 'Developer Employees',
   component: DeveloperEmployees ,
 },
 {
   label: 'Branches Location',
   component: BranchesLocation ,

 },
 
] ;



