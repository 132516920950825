import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Button, Select, MenuItem, Typography, Icon, ButtonGroup } from "@material-ui/core";

// styles
import useStyles from "./styles";
import { useDebounce, useIsArabicLayout, useIsDesktop, useSelectedTheme, useTranslate } from "../../../Hooks";

// Icons
import { ChevronLeftDouble, ChevronRightDouble, ChevronLeft, ChevronRight } from "../../../assets/icons";

function CustomPagination({ totalItems, itemsPerPage, onPageChange, onItemsPerPageChange, isLoading }) {

  const { translate } = useTranslate("SharedV2");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isArabicLayout } = useIsArabicLayout();

  const { isDesktop } = useIsDesktop();

  const styles = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  
  const [inputValue, setInputValue] = useState(currentPage);

  const [selectOpen, setSelectOpen] = useState(false);

  const debouncedPage = useDebounce(inputValue, 1000);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setInputValue(page); // Update input value when page changes
      onPageChange(page);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(totalPages);
  };

  useEffect(() => {
    handlePageChange(debouncedPage);
  }, [debouncedPage]);

  const renderPageButtons = () => {
    const buttons = [];

    const steps = isDesktop ? 2 : 1;

    // Previous and first page buttons
    buttons.push(
      <Button 
        key="leftDouble" 
        onClick={handleFirstPage} 
        disabled={currentPage === 1}
        classes={{ disabled: styles.disabledButton }}
      >
        {isArabicLayout ? 
          <ChevronRightDouble width="20" height="20" fill={palette.foreground.secondary} />
          :
          <ChevronLeftDouble width="20" height="20" fill={palette.foreground.secondary} />
        }
      </Button>
      ,
      <Button
        key="previous"
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        classes={{ disabled: styles.disabledButton }}
      >
        {!isArabicLayout && 
          <ChevronLeft 
            width="20" 
            height="20" 
            fill={palette.foreground.secondary} 
          />
        }
        {isDesktop ? translate("previous") : null}
        {isArabicLayout && 
          <ChevronRight 
            width="20" 
            height="20" 
            fill={palette.foreground.secondary} 
          />
        }
      </Button>
    );

    // Pages before current
    for (let i = Math.max(1, currentPage - steps); i < currentPage; i++) {
      buttons.push(
        <Button key={i} onClick={() => handlePageChange(i)}>{i}</Button>
      );
    }

    // Current page input
    buttons.push(
      <Button key="current" className={styles.activePageButton}>
        <input
          type="number"
          min={1}
          max={totalPages}
          value={inputValue}
          onChange={(e) => {
            if (Number(e.target.value) !== 0 && Number(e.target.value) <= totalPages)
              setInputValue(Number(e.target.value));
          }}
          disabled={isLoading}
        />
      </Button>
    );

    // Pages after current
    for (let i = currentPage + 1; i <= Math.min(totalPages, currentPage + steps); i++) {
      buttons.push(
        <Button key={i} onClick={() => handlePageChange(i)}>{i}</Button>
      );
    }

    // Next and last page buttons
    buttons.push(
      <Button 
        key="next" 
        onClick={handleNextPage} 
        disabled={currentPage === totalPages}
        classes={{ disabled: styles.disabledButton }}
      >
        {isArabicLayout && 
          <ChevronLeft
            width="20" 
            height="20" 
            fill={palette.foreground.secondary} 
          />
        }
        {isDesktop ? translate("next") : null}
        {!isArabicLayout && 
          <ChevronRight 
            width="20" 
            height="20" 
            fill={palette.foreground.secondary} 
          />
        }
      </Button>
      ,
      <Button 
        key="rightDouble" 
        onClick={handleLastPage} 
        disabled={currentPage === totalPages}
        classes={{ disabled: styles.disabledButton }}
      >
        {isArabicLayout ? 
          <ChevronLeftDouble width="20" height="20" fill={palette.foreground.secondary} />
          :
          <ChevronRightDouble width="20" height="20" fill={palette.foreground.secondary} />
        }
      </Button>
    );

    return buttons;
  };

  const handleSelectToggle = useCallback(() => {
    setSelectOpen((prev) => !prev);
  }, []);

  if (totalItems < itemsPerPage) 
    return null;

  return (
    <Box className={styles.root}>
      <Box className={styles.contentWrapper}>
        <Typography className={styles.typography} variant='body1'>
          {translate("showing")}
        </Typography>
        <Select
          MenuProps={{
            PaperProps: {
              elevation: 0,
            },
          }}
          open={selectOpen}
          value={itemsPerPage}
          onChange={(e) => {
            onItemsPerPageChange(e.target.value)
            setSelectOpen(false);
          }}
          onOpen={() => setSelectOpen(true)}
          onClose={() => setSelectOpen(false)}
          className={styles.select}
          IconComponent={() => (
            <Icon 
              className={clsx("icon-down-arrow", styles.dropdownIcon)} 
              onClick={handleSelectToggle}
            />
          )}
          disabled={isLoading}
        >
          {[25, 50, 100, 150].map((number) => (
            <MenuItem key={number} value={number}>{number}</MenuItem>
          ))}
        </Select>
        <Typography className={styles.typography} variant='body1'>
          {translate("out-of-items", { number: totalItems })}
        </Typography>
        {isDesktop && 
          <Typography className={styles.typography} variant='body1'>| {totalPages} {translate("of-pages")}</Typography>
        }
      </Box>
      <ButtonGroup 
        disabled={isLoading} 
        className={styles.buttonGroup} 
        size="small" 
        disableRipple
      >
        {renderPageButtons()}
      </ButtonGroup>
    </Box>
  );
};

CustomPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

CustomPagination.defaultProps = {
  totalItems: 0,
  itemsPerPage: 25,
  onPageChange: () => {},
  onItemsPerPageChange: () => {},
  isLoading: false,
};

export default CustomPagination;
