import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useReducer,
  } from "react";
  import { useTranslation } from "react-i18next";
  import {
    Tables
  } from "../../../../../../Components";
  import {
    GetParams,
    showError,
    showSuccess,
    showWarn,
  } from "../../../../../../Helper";
  import { TableActions } from '../../../../../../Enums'; 
  import { MessageConfirmDialog } from "../../../../../../SharedComponents";


  
  export const BranchesLocationTable = ({
    translationPath,
    parentTranslationPath,
  }) => {
    const { t } = useTranslation(parentTranslationPath, "Shared");  
    const [allBranchesLocations, setAllBranchesLocations ] = useState({ result :  [] , totalCount : 0  }) ; 
    const [filter , setFilter] = useState({pageIndex  : 0 , pageSize :  25 ,  search :''});
    const [ activeItem ,  setActiveItem ] = useState(null) ; 
    const [isOpenConfirmDialog , setIsOpenConfirmDialog ] = useState(false);
    const [isMissingInfoMSGOpen , setIsMissingInfoMSGOpen] = useState(false) ; 


    const DeleteHandler = useCallback(async () => {  
        // const res = await DeleteGeneralPropertyCriteria(
        //   activeItem && activeItem.propertyRatingGeneralPropertyCriteriaScoreId
        // );
        // if (res && res.ErrorId) {
        //   showError(t(`${translationPath}failed-to-delete-property-age`));
        // } else if (!(res && res.status && res.status !== 200)) {
        //   setIsMissingInfoMSGOpen(false) ; 
        //   showSuccess(t(`${translationPath}property-age-deleted-sucessfuully`));
       
        // } else showError(t(`${translationPath}failed-to-delete-property-age`));
      },[activeItem]);


    const tableActionClicked = useCallback(
        (actionEnum, item, focusedRow, event) => {
          event.stopPropagation();
          event.preventDefault();
          setActiveItem(item);
          if (actionEnum === TableActions.deleteText.key) {
            setIsMissingInfoMSGOpen(true);
          } else if (actionEnum === TableActions.editText.key)
            {
            
           }
        },
        []
      );

      const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
      };
      const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
      };


  
    return (
      <div className="d-flex-column mt-2">
        <Tables
            data={allBranchesLocations ? allBranchesLocations.result : []}
            headerData={[
              {
                id: 1,
                label: t(`${translationPath}branch-name`),
                input: 'branch-name',
              },
              {
                id: 2,
                label: t(`${translationPath}country`),
                component: (item) => (
                  <div>
                    {item && item.country && item.country.lookupItemName }
                  </div>
                ),
              },
              {
                id: 3,
                label: t(`${translationPath}city`),
                component: (item) => (
                  <div>
                    {item && item.city && item.city.lookupItemName }
                  </div>
                ),
              },
              {
                id: 4,
                label: t(`${translationPath}District`),
                component: (item) => (
                  <div>
                    {item && item.district && item.district.lookupItemName }
                  </div>
                ),
              },
              {
                id: 6,
                label: t(`${translationPath}community`),
                input: 'Community',
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.community && item.community.lookupItemName }
                  </div>
                ),

              },
              {
                id: 7,
                label: t(`${translationPath}subCommunity`),
                input: 'subCommunity',
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.subCommunity && item.subCommunity.lookupItemName }
                  </div>
                ),

              },
              {
                id: 8,
                label: t(`${translationPath}street`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.street}
                  </div>
                ),

              },
              {
                id: 9,
                label: t(`${translationPath}building-name`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.buildingName}
                  </div>
                ),

              },
              {
                id: 10 ,
                label: t(`${translationPath}building-number`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.buildingNumber}
                  </div>
                ),

              },
              {
                id: 11,
                label: t(`${translationPath}floor-number`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.floorNumber}
                  </div>
                ),

              },
              {
                id: 12 ,
                label: t(`${translationPath}office-number`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.office_number}
                  </div>
                ),

              },{
                id: 13,
                label: t(`${translationPath}postal-zip-code`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.postal_zip_code}
                  </div>
                ),

              },
              {
                id: 14,
                label: t(`${translationPath}po-box`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.po_box}
                  </div>
                ),

              },
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            defaultActions={[
              {
                enum: TableActions.editText.key,
                isDisabled: false,
                externalComponent: null,
              },
              {
                enum: TableActions.deleteText.key,
                isDisabled: false,
                externalComponent: null,
              },
            ]}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            itemsPerPage={filter.pageSize}
            totalItems={allBranchesLocations ? allBranchesLocations.totalCount : 0}
          />
        {isMissingInfoMSGOpen  && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-branch-location",
            body :(t(`${translationPath}are-you-sure-you-want-to-delete-branch-location`) + ' ' + ((activeItem && activeItem.lookupItemName && t(`${translationPath}branch`)) || '') + `  ${(activeItem && activeItem.lookupItemName)||''}`)

          }}
          confirmButton={{
            label: (t(`${translationPath}confirm`)) , 
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label  :(t(`${translationPath}discard`)),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      </div>
    );
  };
  