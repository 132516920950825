import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TabsComponent } from '../../../../../Components';
import { ViewTypesEnum } from '../../../../../Enums';
import { DeveloperUnitsSale  } from './DeveloperUnitsSale';
import { DeveloperUnitsLease  } from './DeveloperUnitsLease';

export const DeveloperProfileUnitsComponent = () => {
  const parentTranslationPath = 'UnitsView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  const [viewType, setViewType] = useState(ViewTypesEnum.cards.key);
  const [operationType, setOperationType] = useState("Sale");
  const [activeTab, setActiveTab] = useState(0);
  const tabsData = [
    {
      label: t(`sale`),
      component: DeveloperUnitsSale,
    },
    {
      label: t(`lease`),
      component: DeveloperUnitsLease,
    },
  ]

  const onTabChanged = (e, tabIndex) => {
    setActiveTab(tabIndex);
    if( tabIndex=== 0 ){
      setOperationType("Sale");
    } else if( tabIndex=== 1 ){
      setOperationType("Lease");
    }
  };

  const onTypeChanged = useCallback((activeType) => {
    setViewType(activeType);
  }, []);
  return (
    <>
    <div className='contact-units-wrapper mt-3'>
    <TabsComponent
          data={tabsData}
          labelInput='label'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-curved'
          currentTab={activeTab}
          onTabChanged={onTabChanged}
          dynamicComponentProps={{
            operationType,
            onTypeChanged,
            viewType,
            parentTranslationPath,
            translationPath,
           }}
         />
    </div>
    </>
  );
};

DeveloperProfileUnitsComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
