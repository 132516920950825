import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  MenuItem,
  Menu,
  IconButton,
  Box,
  Divider,
  Avatar,
} from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { CustomIconButton } from "../CustomIconButton";
import { useSelectedTheme, useTranslate } from "../../../Hooks";
import { getDownloadableLink, truncateString } from "../../../Helper";
import {
  CustomBadge,
  SocialLinks,
  CustomCheckbox,
  LeadTypeBadges,
  CustomCopyToClipboard,
} from "../..";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// Icons
import {
  ArrowUpRight,
  MoreVertIcon,
  ActivityIcon,
  ListIcon,
  StarOneIcon,
} from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const ContactCard = ({ item, setActiveItem }) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");
  const history = useHistory();

  const { setAlertBoxContent } = useVerticalNav();

  const {
    setIsAddTaskDialogOpen,
    setAddActivity,
    actionableContacts,
    setActionableContacts,
    advancedSearchBody,
  } = useContext(ContactLayoutContext);

  useEffect(() => {
    if (!actionableContacts?.contactIds?.includes(item?.id)) {
      setChecked(false);
    }
  }, [actionableContacts?.contactIds?.length]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorEl({
      top: rect.top + 25,
      left: rect.left + 15,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      label: "Add task",
      icon: (
        <ListIcon width="16" height="16" fill={palette.foreground.quarterary} />
      ),
      handleOnclick: () => setIsAddTaskDialogOpen(true),
    },
    {
      label: "Add activity",
      icon: (
        <ActivityIcon
          width="16"
          height="16"
          fill={palette.foreground.quarterary}
        />
      ),
      handleOnclick: () => setAddActivity(true),
    },
    {
      label: "Add to favorite",
      icon: (
        <StarOneIcon
          width="16"
          height="16"
          fill={palette.foreground.quarterary}
        />
      ),
    },
  ];

  const BadgeNumberText = (items = []) => (
    <CustomBadge
      Style={{ padding: "0 4px", height: "18px", marginLeft: "5px" }}
      label={`+${items.length}`}
      SizeVariant={"small"}
      BackgroundColor={palette.utility.utility_gray_50}
      BorderColor={palette.utility.utility_gray_200}
      Color={palette.utility.utility_gray_700}
    />
  );

  const [checked, setChecked] = useState(
    actionableContacts?.contactIds?.includes(item?.id)
  );

  // handleChange function to add or remove the item based on whether the checkbox is checked or not
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setActionableContacts((prevState) => ({
        ...prevState,
        contactIds: [...new Set([...prevState.contactIds, item.id])],
      }));
    } else {
      // Remove the contact id from the actionableContacts array if unchecked

      setActionableContacts((prevState) => ({
        ...prevState,
        contactIds: prevState.contactIds.filter((id) => id !== item.id),
      }));
    }
  };

  const shouldProceedWithAction = () => {
    if (
      (actionableContacts?.action === "bulk_update" &&
        advancedSearchBody?.criteria?.contactTypeStr?.[0]?.value) ||
      actionableContacts?.action === "merge" ||
      actionableContacts?.action === "pull"
    ) {
      return true;
    }

    return false;
  };

  return (
    <Box
      key={item?.contactId || item?.id}
      className={styles.ContactCardWrapper}
      onClick={(event) => {
        event.stopPropagation();
        setActiveItem(item);
      }}
    >
      <Box className={styles.cardHeader}>
        <Box
          style={{
            width: "28px",
            cursor:
              actionableContacts?.action === "merge" &&
              (item?.isWithTransaction === 1 ||
                (actionableContacts?.contactIds?.length >= 10 &&
                  !actionableContacts?.contactIds?.includes(item?.id))) // Disable if length > 10 and not already selected
                ? "not-allowed"
                : "pointer",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {shouldProceedWithAction() && (
            <CustomCheckbox
              checked={checked}
              onChange={handleChange}
              disabled={
                actionableContacts?.action === "merge" &&
                (item?.isWithTransaction === 1 ||
                  (actionableContacts?.contactIds?.length >= 10 &&
                    !actionableContacts?.contactIds?.includes(item?.id))) // Disable if length > 10 and not already selected
              }
            />
          )}
        </Box>
        <Box className={styles.flex}>
          {(item?.psi_opportunity?.toLowerCase() === "yes" ||
            item?.opportunityContact?.toLowerCase() === "yes") && (
            <>
              <Box className={styles.floatingBox} />
              <Box className={styles.userTextWrapper}>
                {item?.psi_opportunity?.toLowerCase() === "yes" && (
                  <label className={styles.psiLabel}>PSI</label>
                )}
                <Box
                  className={styles.userText}
                  style={
                    item?.opportunityContact?.toLowerCase() === "yes"
                      ? {
                          background: "#FFF",
                          color: palette.text.brand_tertiary,
                          border: `1px solid #8C734B`,
                        }
                      : {}
                  }
                >
                  Opportunity
                </Box>
              </Box>
            </>
          )}

          <Box className={styles.avatarWrapper}>
            {getDownloadableLink(item.imagePath) && (
              <Avatar
                className={
                  item.imagePath ? styles.avatar : styles.defaultAvatar
                }
                src={
                  item.imagePath
                    ? getDownloadableLink(item?.imagePath)
                    : item?.contact_type_id === 2 //"CORPORATE"
                    ? "/images/contacts/CorporateAvatar.svg"
                    : item?.gender?.lookupItemName === "Male"
                    ? "/images/contacts/MaleAvatar.svg"
                    : "/images/contacts/FemaleAvatar.svg"
                }
              />
            )}
          </Box>
        </Box>

        <Box
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <IconButton
            aria-controls="more-button"
            aria-haspopup="true"
            aria-label="more-button"
            id="more-button"
            disableRipple
            onClick={handleClick}
            className={styles.menuIcon}
          >
            <MoreVertIcon
              width="20"
              height="20"
              fill={
                anchorEl
                  ? palette.foreground.quarterary
                  : palette.foreground.quinary
              }
            />
          </IconButton>
          <Menu
            id="more-button"
            PaperProps={{ elevation: 0 }}
            MenuListProps={{ className: styles.customMenuList }}
            anchorReference="anchorPosition"
            anchorPosition={
              anchorEl ? { top: anchorEl.top, left: anchorEl.left } : undefined
            }
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {options.map(({ label, icon, handleOnclick }) => (
              <MenuItem
                key={label}
                onClick={(event) => {
                  event.stopPropagation();
                  handleOnclick();
                  handleClose();
                }}
                className={styles.option}
              >
                {icon}
                <span>{label}</span>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>

      <Box className={styles.detailsWrapper}>
        <Box className={styles.details}>
          <label>
            {item.contact_type_id === 2
              ? translate("CORPORATE")
              : translate("INDIVIDUAL")}
          </label>

          {(item?.contact_class?.lookupItemName === "VIP" ||
            item?.company_class?.lookupItemName === "VIP") && (
            <CustomBadge
              Style={{
                padding: "0 2px",
                borderRadius: "2px",
                lineHeight: "20px",
              }}
              label={translate("VIP")}
              BackgroundColor={palette.utility.brand_300}
              BorderColor={palette.utility.brand_300}
              Color={"#FFF"}
            />
          )}

          {(item?.contact_class?.lookupItemName === "VVIP" ||
            item?.company_class?.lookupItemName === "VVIP") && (
            <CustomBadge
              Style={{
                padding: "0 2px",
                borderRadius: "2px",
                lineHeight: "20px",
              }}
              label={translate("VVIP")}
              BackgroundColor={palette.utility.brand_600}
              BorderColor={palette.utility.brand_600}
              Color={"#FFF"}
            />
          )}

          {item?.isBulkUpload && (
            <span style={{ textDecoration: "underline" }}>
              {translate("BULK")}
            </span>
          )}
        </Box>
        <Box
          className={styles.nameContainer}
          onClick={() => {
            history.push(
              `/home/Contacts-CRM/contact-profile-overview?formType=${
                item.contact_type_id
              }&id=${item?.contactId || item?.id}`
            );
          }}
        >
          <label>
            {truncateString(
              item?.company_name
                ? `${item?.company_name}`
                : `${item?.first_name || ""} ${item?.last_name || ""}`,
              45
            )}
          </label>
          <CustomIconButton
            variant="text"
            size="xs"
            boxShadow="none"
            color="secondary"
          >
            <ArrowUpRight
              width="20"
              height="20"
              fill={palette.button.secondaryGray_fg}
            />
          </CustomIconButton>
        </Box>
        <Box className={styles.idSection}>
          <CustomCopyToClipboard
            data={item.contactId || item?.id} // copy
            childrenData={"#" + (item.contactId || item?.id)} // render
            copyDoneShowTime={1000}
          />

          <CustomBadge
            label={item?.data_completed + "%" || "0%"}
            SizeVariant={"small"}
            BackgroundColor={
              palette.utility[
                item?.data_completed >= 50 ? "brand_50" : "error_50"
              ]
            }
            BorderColor={
              palette.utility[
                item?.data_completed >= 50 ? "brand_200" : "error_200"
              ]
            }
            Color={
              palette.utility[
                item?.data_completed >= 50 ? "brand_700" : "error_700"
              ]
            }
          />
        </Box>
      </Box>

      <Box style={{ height: "24px" }}>
        {item?.leadTypes && <LeadTypeBadges leadTypesList={item.leadTypes} />}
      </Box>
      <Box style={{ direction: "ltr", height: "30px" }}>
        <SocialLinks activeItem={item} gap={8} />
      </Box>

      <Box>
        <Box className={styles.userInfo}>
          <label>
            {item?.language?.lookupItemName}
            {item?.nationality?.lookupItemName ||
            item?.company_nationality?.lookupItemName
              ? `, ${
                  item?.nationality?.lookupItemName ||
                  item?.company_nationality?.lookupItemName
                }`
              : ""}
          </label>

          <span>
            {(item?.mobile?.phone || item?.landline_number?.phone)?.substring(
              0,
              10
            )}
            {(item?.other_contact_mobile_no?.others?.length > 0 ||
              item?.landline_number?.others?.length > 0) &&
              BadgeNumberText(
                item?.other_contact_mobile_no?.others ||
                  item?.landline_number?.others
              )}

            {(item?.email_address?.email || item?.general_email?.email) && (
              <span className={styles.hyphen}>-</span>
            )}

            {(item?.email_address?.email || item?.general_email?.email) &&
              truncateString(
                item?.email_address?.email || item?.general_email?.email
              )}
            {(item?.email_address?.others?.length > 0 ||
              item?.general_email?.others?.length > 0) &&
              BadgeNumberText(
                item?.email_address?.others || item?.general_email?.others
              )}
          </span>
        </Box>

        <Divider className={styles.divider} />

        <Box className={styles.dateContainer}>
          <label>Creation On:</label>

          <Box className={styles.dateText}>
            <label>{moment(item.createdOn).format("DD MMM YYYY")}</label>
            <span>{truncateString(item?.createdBy)}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ContactCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.any,
  setActiveItem: PropTypes.func,
};

ContactCard.defaultProps = {
  item: {},
  setActiveItem: () => {},
};

export default ContactCard;
