import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { Children, useState } from 'react';
import { PaginationComponent, Spinner } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { GlobalTranslate, showError } from '../../../../../Helper';
import Recommendation from './Recommendation';
import MoreDetails from './MoreDetails';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents';
import EvaluationChip from './EvaluationChip';
import AudioPlayer from './AudioPlayer';

const useStyles = makeStyles({
  root: {
    width: '320px !important',
    height: '380px !important',
    position: 'relative',
    padding: 4,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: 16,
  },
  label: {
    fontSize: 12,
    color: '#8e8e93',
  },
  copyComponent: {
    paddingRight: '20px !important',
  },
});

function AgentCallCardView({
  data,
  loading,
  parentTranslationPath,
  translationPath,
  filter,
  setFilter,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [activeCall, setActiveCall] = useState(null);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isAudioDialogOpen, setIsAudioDialogOpen] = useState(false);
  const classes = useStyles();
  const openAudio = (call) => {
    setActiveCall(call);
    setIsAudioDialogOpen(true);
  };

  return (
    <div>
      <Spinner isActive={loading} isAbsolute />
      <div className='d-flex gap-1 flex-wrap fj-center'>
        {Children.toArray(
          data.result.map((call) => (
            <Card className={classes.root} variant='outlined'>
              <CardHeader
                title={
                  <div className='d-flex-h-between fa-center'>
                    <Typography className={classes.cardTitle}>
                      <strong>
                        {t(`${translationPath}lead-id`)}:{' '}
                        <CopyToClipboardComponents
                          data={call.leadId}
                          childrenData={call.leadId}
                          CustomizationClassName={classes.copyComponent}
                        />
                      </strong>
                    </Typography>
                    <EvaluationChip
                      sentimentEvaluation={call.sentimentEvaluation}
                    />
                  </div>
                }
              />
              <CardContent>
                <Typography
                  className={classes.title}
                  color='textPrimary'
                  gutterBottom
                >
                  <strong className={classes.label}>
                    {t(`${translationPath}agent-name`)}:{' '}
                  </strong>
                  <br />
                  {call.agent}
                </Typography>
                <Typography
                  className={classes.title}
                  color='textPrimary'
                  gutterBottom
                >
                  <strong className={classes.label}>
                    {t(`${translationPath}client-name`)}:{' '}
                  </strong>
                  <br />
                  {call.lead}
                </Typography>
                <Typography
                  className={classes.title}
                  color='textPrimary'
                  gutterBottom
                >
                  <strong className={classes.label}>
                    {t(`${translationPath}duration`)}:{' '}
                  </strong>
                  <br />
                  {call.callDuration ?? '-'}
                </Typography>
                <Typography
                  className={classes.title}
                  color='textPrimary'
                  gutterBottom
                >
                  <strong className={classes.label}>
                    {t(`${translationPath}date`)}:{' '}
                  </strong>
                  <br />
                  {moment(call.activityDate).format('MM/DD/YYYY, h:mm A')}
                </Typography>
                <Typography
                  className={classes.title}
                  color='textPrimary'
                  gutterBottom
                >
                  <strong className={classes.label}>
                    {t(`${translationPath}recommendation`)}:{' '}
                  </strong>
                  <br />
                  <Recommendation
                    recommendation={call.adviceForAgent}
                    parentTranslationPath={parentTranslationPath}
                  />
                </Typography>
              </CardContent>
              <CardActions className={classes.actions}>
                <Button
                  onClick={() => openAudio(call)}
                  size='small'
                  className='capitalize p-2'
                  color='primary'
                >
                  {t('listen-to-call')}
                </Button>
                <Button
                  onClick={() => {
                    setIsDetailsDialogOpen(true);
                    setActiveCall(call);
                  }}
                  size='small'
                  className='capitalize p-2'
                  color='primary'
                >
                  {t('more-details')}
                </Button>
              </CardActions>
            </Card>
          ))
        )}
      </div>
      <PaginationComponent
        pageIndex={filter.pageIndex - 1}
        totalCount={data.totalCount}
        pageSize={filter.pageSize}
        onPageIndexChanged={(index) =>
          setFilter((prevFilter) => ({
            ...prevFilter,
            pageIndex: index + 1,
          }))
        }
        onPageSizeChanged={(size) =>
          setFilter((prevFilter) => ({
            ...prevFilter,
            pageSize: size,
          }))
        }
      />
      {isDetailsDialogOpen && (
        <MoreDetails
          open
          onClose={() => {
            setIsDetailsDialogOpen(false);
            setActiveCall(null);
          }}
          call={activeCall}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isAudioDialogOpen && (
        <AudioPlayer
          parentTranslationPath={parentTranslationPath}
          audioLink={activeCall?.callAudioFile}
          onClose={() => {
            setActiveCall(null);
            setIsAudioDialogOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default AgentCallCardView;
