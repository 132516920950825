export const IndividualKycFormMap = {
  "PersonalDetails": {
    "sectionLabel": "Personal Details",
    "sectionKey": "PersonalDetails",
    "sectionFields": [
      {
        "label": "Title",
        "input": "title",
        "fieldType": "radioButton",
        "optionsKey": "Salutation",
        "lookupName": "Salutation",
        "disabled": true
      },
      {
        "label": "Surname",
        "input": "surname",
        "fieldType": "readOnly"
      },
      {
        "label": "Forenames",
        "input": "forenames",
        "fieldType": "readOnly"
      },
      {
        "label": "Date Of Birth",
        "input": "dateOfBirth",
        "fieldType": "readOnly",
        "isDate": true,
      },
      {
        "label": "Place of birth",
        "input": "placeOfBirth",
        "fieldType": "textInput"
      },
      {
        "label": "Nationality",
        "input": "nationality",
        "fieldType": "readOnly"
      },
      {
        "label": "Dual Nationality",
        "input": "dualNationality",
        "fieldType": "textInput"
      },
      {
        "label": "Country of residence",
        "input": "countryOfResidenceInfo",
        "fieldType": "readOnly"
      },
      {
        "label": "Gender",
        "input": "gender",
        "fieldType": "radioButton",
        "optionsKey": "Gender",
        "disabled": true
      },
      {
        "label": "Occupation or profession",
        "input": "occupationInfo",
        "fieldType": "selectInput",
        "optionsKey": "CustomerType",
        "lookupName": "CustomerType"
      },
      {
        "label": "Name of establishment or employer",
        "input": "nameOfEmployer",
        "fieldType": "textInput"
      },
      {
        "label": "Source of funds/ income/ wealth",
        "input": "sourceOfFundInfo",
        "fieldType": "selectInput",
        "optionsKey": "SourceOfFund",
        "lookupName": "CustomerSourceOfFund/Wealth"
      },
      {
        "label": "Method of payment",
        "input": "paymentMethodInfo",
        "fieldType": "selectInput",
        "optionsKey": "MethodOfPayment",
        "lookupName": "NatureOfTransaction(PaymentMethod)"
      }
    ],
    "sectionColNo": 2
  },
  "PEP": {
    "sectionLabel": "PEP",
    "sectionKey": "PEP",
    "sectionFields": [
      {
        "label": "Is the Individual a Politically Exposed Person (PEP)?",
        "input": "isPEP",
        "fieldType": "radioButton",
        "optionsKey": "Yes_No"
      },
      {
        "label": "Is the client related to PEP",
        "input": "isRelatedToPEP",
        "fieldType": "radioButton",
        "optionsKey": "Yes_No"
      },
      {
        "label": "If client is related to a PEP, please indicate the relationship and name of PEP",
        "input": "nameOfPEP",
        "fieldType": "textInput"
      }
    ],
    "sectionColNo": 2
  },
  "Address": {
    "sectionLabel": "Residential Address / Permanent address",
    "sectionKey": "Address",
    "sectionFields": [
      {
        "label": "Address",
        "input": "address",
        "fieldType": "readOnly"
      },
      {
        "label": "PO Box / Postal Code",
        "input": "poBox",
        "fieldType": "readOnly"
      },
      {
        "label": "Country",
        "input": "country",
        "fieldType": "readOnly"
      },
      {
        "label": "City",
        "input": "city",
        "fieldType": "readOnly"
      },
      {
        "label": "State / Province",
        "input": "state",
        "fieldType": "textInput"
      },
      {
        "label": "E-mail",
        "input": "emailAddress",
        "fieldType": "readOnly"
      },
      {
        "label": "Mobile",
        "input": "contactNumber",
        "fieldType": "readOnly"
      }
    ],
    "sectionColNo": 2
  },
  "OnBehalf": {
    "sectionLabel": "On Behalf",
    "sectionKey": "OnBehalf",
    "sectionFields": [
      {
        "label": "Is the customer is acting on behalf of another person?",
        "input": "isActingOnBehalf",
        "fieldType": "radioButton",
        "optionsKey": "Yes_No"
      },
      {
        "label": "Name of beneficial owner",
        "input": "nameOfBeneficiary",
        "fieldType": "textInput"
      },
      {
        "label": "Nature of relationship",
        "input": "natureOfRelationship",
        "fieldType": "textInput"
      },
      {
        "label": "Nationality",
        "input": "nationalityOfBeneficiaryInfo",
        "fieldType": "selectInput",
        "optionsKey": "Country",
        "lookupName": "Country"
      },
      {
        "label": "ID type",
        "input": "idType",
        "fieldType": "textInput"
      },
      {
        "label": "PEP",
        "input": "isBeneficiaryPEP",
        "fieldType": "radioButton",
        "optionsKey": "Yes_No"
      },
      {
        "label": "E-mail",
        "input": "emailAddressOfBeneficiary",
        "fieldType": "textInput"
      },
      {
        "label": "Contact Number",
        "input": "contactNumberOfBeneficiary",
        "fieldType": "textInput"
      }
    ],
    "sectionColNo": 2
  },
  "Documents": {
    "sectionLabel": "Documents",
    "sectionKey": "Documents",
    "sectionFields": [
      {
        "label": "Please submit the documents and tick against the documents attached",
        "input": "attachedDocTypes",
        "fieldType": "groupCheckbox"
      }
    ],
    "sectionColNo": 2
  },
  "SourceOfFund": {
    "sectionLabel": "Declaration of Source of fund",
    "sectionKey": "SourceOfFund",
    "sectionFields": [
      {
        "label": "Declaration",
        "input": "declaration",
        "fieldType": "singleCheckbox",
        "boxLabel": "I agree and understand that by signing the E-Signature , I confirm that electronic signature is legal , binding and equivalent of my manual / handwritten signature."
      },
      {
        "label": "Client name",
        "input": "-",
        "fieldType": "textInput",
        "forClientOnly": true,
      },
      {
        "label": "Date",
        "input": "-",
        "fieldType": "textInput",
        "forClientOnly": true,
      },
      {
        "label": "Client signature",
        "input": "-",
        "fieldType": "signature",
        "forClientOnly": true,
        "placeholder": "Client Signature"
      },
      {
        "label": "Name of the Agent / Representative",
        "input": "-",
        "fieldType": "readOnly"
      },
      {
        "label": "Date",
        "input": "-",
        "fieldType": "readOnly"
      },
      {
        "label": "Signature of the Agent/Representative",
        "input": "-",
        "fieldType": "signature",
        "placeholder": "Agent/Representative Signature"
      }
    ],
    "beforeText" : true,
  }
};
