
  import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useReducer,
  } from "react";
  import { ButtonBase } from '@material-ui/core';
  import { Button } from 'react-bootstrap';
  import Tooltip from "@material-ui/core/Tooltip";
  import { useSelector } from "react-redux";
  import moment from "moment";
  import { useTranslation } from "react-i18next";
  import {
    Inputs,
  } from "../../../../Components";
  import {
    GetParams,
    showError,
    showSuccess,
    showWarn,
  } from "../../../../Helper";
  import { getIsValidURL,isURL, matchYoutubeUrl , matchFacebookUrl  , matchInstagramUrl , matchLinkedinUrl , matchSnapchatUrl , matchTiktokLink  } from '../../../../Helper/Link.helper';

  import {  SocialMediaLinkComponentTable , SocialMediaLinkComponent } from './SocialMediaLinkComponent' ; 
    
  export const SocialMediaLink = ({
    translationPath,
    parentTranslationPath,
  }) => {
    const { t } = useTranslation(parentTranslationPath, "Shared");
     const  [isLoading , setIsLoading] = useState({ save : false  , info : false}) ;
     const [isOpenEditDialog ,  setIsOpenEditDialog] = useState(false);
  
   
  
    const [defaultSelected, setDefaultSelected] = useState({
      linkedInLink :  null , 
      facebookLink : null , 
      instagramAccountNumber : null  , 
      snapchatLink : null , 
      tiktokLink: null , 
      youtubeLink  : null  , 
      x : null , 

     
    });
  
    const reducer = (select, action) => {
      if (action.id !== "edit") return { ...select, [action.id]: action.value };
      if (action.id === "edit") {
        return {
          ...action.value,
        };
      }
    };
  
    const [state, setState] = useReducer(reducer, {
      ...defaultSelected,
    });
    
    console.log('state' , state ) ; 
  
    return (
      <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column mt-2">
        <div className="w-100 agreement-details-add">
          <div className="w-100">
            <div className="part1 mt-2 mb-2">
              <div className="px-2 item">
              <Inputs
                  isAttachedInput
                  idRef={"LinkedInLinkRef"}
                  labelValue={"linkedIn-link"}
                  type={"text"}
                  value= { state.linkedInLink }
                  onInputChanged={(e) => 
                  {
                    setState({ id :  'linkedInLink', value: e.target.value });

                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchLinkedinUrl(state.linkedInLink|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.linkedInLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
                />
              </div>
              
              <div className="px-2 item">
              <Inputs
                  isAttachedInput
                  idRef={"Facebook LinkRef"}
                  labelValue={"facebook-link"}
                  type={"text"}
                  value= {state.facebookLink }
                  onInputChanged={(e) => 
                    {
                      setState({ id :  'facebookLink', value: e.target.value });

                    }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchFacebookUrl(state.facebookLink|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.facebookLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
              />
              </div>
               <div className="px-2 item mt-1">
               <Inputs
                  isAttachedInput
                  idRef={"InstagramAccountNumberRef"}
                  labelValue={"instagram-account-number"}
                  type={"text"}
                  value= { state.instagramAccountNumber }
                  onInputChanged={(e) => {
                    setState({ id :  'instagramAccountNumber', value: e.target.value });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
               </div>
               <div className="px-2 item mt-1">
               <Inputs
                  isAttachedInput
                  idRef={"SnapchatLinkRef"}
                  labelValue={"snapchat-link"}
                  type={"text"}
                  value= {state.snapchatLink }
                  onInputChanged={(e) => {
                    setState({ id :  'snapchatLink', value: e.target.value });

                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchSnapchatUrl(state.snapchatLink|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.snapchatLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
                />
               </div>
               <div className="px-2 item mt-1">
               <Inputs
                  isAttachedInput
                  value= { state.tiktokLink }
                  idRef={"TikTokLinkRef"}
                  labelValue={"tiktok-link"}
                  type={"text"}
                  onInputChanged={(e) => {
                    setState({ id :  'tiktokLink', value: e.target.value });

                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchTiktokLink(state.tiktokLink|| ''))}
                        onClick={() => {
                          try {
                            window.open(state.tiktokLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}

                />
               </div>
               <div className="px-2 item mt-1">
               <Inputs
                  isAttachedInput
                  value= { state.youtubeLink }
                  idRef={"YouTubeLinkRef"}
                  labelValue={"youTube-link"}
                  type={"text"}
                  onInputChanged={(e) => 
                  {
                    setState({ id :  'youtubeLink', value: e.target.value });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(matchYoutubeUrl(state.youtubeLink || ''))}
                        onClick={() => {
                          try {
                            window.open(state.youtubeLink || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
                />
               </div>
               <div className="px-2 item mt-1">
               <Inputs
                  isAttachedInput
                  value= {state.x }
                  idRef={"XRef"}
                  labelValue={"X"}
                  type={"text"}
                  onInputChanged={(e) => {
                    setState({ id :  'X', value: e.target.value });

                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={!(getIsValidURL(state.X || ''))}
                        onClick={() => {
                          try {
                            window.open(state.X || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
                />
               </div>
              <div className="mt-3 addBtn">
              <ButtonBase
              className='btns theme-solid'
              onClick={() => 
              {
                //saveAgreementDetails() 
              }}
            >
              <span className='mdi mdi-plus' />
              {t(`${translationPath}add`)}
            </ButtonBase>
            <ButtonBase
              className='btns theme-solid'
              onClick={() => 
              {
                setIsOpenEditDialog(true) ; 
              }}
            >
              <span className='mdi mdi-plus' />
              {t(`${translationPath}edit`)}
            </ButtonBase>
            </div>
            </div>
          </div>
        </div>
          <SocialMediaLinkComponentTable 
           translationPath={translationPath}
           parentTranslationPath={parentTranslationPath}
        />
      
           {
            isOpenEditDialog && (
              <SocialMediaLinkComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              isOpen ={isOpenEditDialog}
              onClose={()=> setIsOpenEditDialog(false)}
              onSave={()=> setIsOpenEditDialog(false)}
              />
            )
          
          } 
      </div>
    );
  };
  
  