import React from "react";
import { Box, Paper } from "@material-ui/core";

import { CustomButton } from "../../../Components";
import { useSelectedTheme, useTitle, useTranslate } from "../../../Hooks";

// Icons
import { CloseXIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function ConfirmationDialog({ onClose, onConfirm, content }) {
  const classes = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Box className={classes.modalContainer}>
      <Paper className={classes.modalPaper}>
        <CloseXIcon
          onClick={onClose}
          className={classes.closeXIcon}
          width="24"
          height="24"
          fill={palette.foreground.quinary}
        />
        <Box onClose={onClose} className={classes.container}>
          <Box className={classes.content}>
            <Box id="generic-dialog-description">{content}</Box>
          </Box>

          <Box className={classes.actions}>
            <CustomButton
              boxShadow="xs"
              size="md"
              onClick={onClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </CustomButton>

            <CustomButton
              boxShadow="xs"
              size="md"
              onClick={onConfirm}
              variant="contained"
            >
              Confirm
            </CustomButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default ConfirmationDialog;
