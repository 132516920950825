import React, { useState, useCallback, useEffect } from "react";
import { ButtonBase } from '@material-ui/core';
import { InputAdornment } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  PhonesComponent,
  CheckboxesComponent,
  DatePickerComponent,
} from "../../../../../../../Components";
import { ActivityFilesUploader  } from '../../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader' ;
import PropTypes from "prop-types";
import { getErrorByName } from "../../../../../../../Helper";
import {
  UnitsOperationType,
  PortalsEnum,
  UnitsOperationTypeEnum,
} from "../../../../../../../Enums";
import {
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
} from "../../../../../../../Services";

export const AgreementDetailsContentDialog = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  schema,
  isSubmitted,
  activeItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [items , setItems] = useState({
    allAgreementTypes :[] 
  })  ; 

  const  [isLoading , setIsLoading] = useState({allAgreementTypes : false , save : false  , info : false}) ;
  const [isErrorInUrl, setIsErrorInUrl] = useState(false);

  function isValidUrl(str) {
    const pattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    return pattern.test(str);
  }


  const getLookupItemsByName = async (lookUpName , key ) => {

    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(result && result.status && result.status !== 200)) {
        setItems((item) => ({ ...item, [key]: (result && result.result) || []   }));
    } 
    else  setItems((item) => ({ ...item, [key]: []}));
    setIsLoading((item) => ({ ...item, [key]: false }));

  };

 

  return (
    <div className="dialog-content-wrapper">
    
        <div className="dialog-content-item">
        <AutocompleteComponent
                key={`agreementType`}
                selectedValues={state.agreementType}
                idRef={`agreementTypeId`}
                multiple={false}
                inputPlaceholder={t("AgreementType")}
                data={items &&  items.allAgreementTypes}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.allAgreementTypes}
                withLoader
                withoutSearchButton
                labelValue={"agreement-type"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.allAgreementTypes?.length === 0 )
                 getLookupItemsByName('AgreementType' , 'allAgreementTypes');
                }}
              />
        </div>
        <div className="dialog-content-item w-50">
        <DatePickerComponent
                idRef={"agreementStartDateRef"}
                format="YYYY/MM/DD"
                labelValue={"agreement-start-date"}
                 value={(state && state.agreementStartDate && moment(state.agreementStartDate).isValid() &&  moment(state.agreementStartDate).format('YYYY/MM/DD'))}
                onDateChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
        </div>
      <div className="dialog-content-item">
        <Inputs
                isAttachedInput
                idRef={"agreementDurationMonthsRef"}
                labelValue={"agreement-duration-months"}
                type={"number"}
                withNumberFormat
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
        </div>
        <div className="dialog-content-item">
          <DatePickerComponent
                idRef={"AgreementEndDateRef"}
                format="YYYY/MM/DD"
                labelValue={"agreement-end-date"}
                onDateChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
          </div>
        <div className="dialog-content-item">
        <Inputs
                isAttachedInput
                idRef={"projectNameRef"}
                labelValue={"project-name"}
                type={"number"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
        </div>
        <div className="dialog-content-item">
        <AutocompleteComponent
                key={`signingBranch`}
                idRef={`signingBranchRef`}
                multiple={false}
                inputPlaceholder={t("signing-branch")}
                data={[]}
                //inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && t(`${translationPath}${option.name}`)) || ""}
                onChange={(e, v) => {}}
                onInputKeyUp={(e) => {}}
                // isLoading={isLoading}
                // withLoader
                withoutSearchButton
                labelValue={"signing-branch"}
                filterOptions={(options) => options}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
       

        </div>
          <div className="dialog-content-item">
          <Inputs
                idRef={`authorizedSignatoryRef`}
                labelClasses=""
                key={`authorizedSignatory`}
                labelValue={"authorized-signatory"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
          
          </div>
          <div className="dialog-content-item">
          <Inputs
                idRef={`psiCommissionRef`}
                labelClasses=""
                key={`PSI-commission`}
                labelValue={"psi-commission"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              /> 
          </div>
          <div className="dialog-content-item">
              <AutocompleteComponent
                key={`marketingBudgetAllocation`}
                idRef={`marketingBudgetAllocationRef`}
                multiple={false}
                inputPlaceholder={t("marketing-budget-allocation")}
                data={[{ key : 'yes' , name : 'yes'} , { key : 'no' , name : 'no'}]}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && t(`${translationPath}${option.name}`)) || ""}
                onChange={(e, v) => {}}
                withoutSearchButton
                labelValue={"marketing-budget-allocation"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
               <div className="dialog-content-item">
               <Inputs
                idRef={`budget-amount`}
                labelClasses=""
                key={`budget-amount`}
                labelValue={"budget-amount"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
              </div>
           
            <div className='mt-2 dialog-content-item w-100'>
             <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              multiple={true}
              accept={'/*'}
           />
          </div>            
       </div>
  );
};

AgreementDetailsContentDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  isSubmitted: PropTypes.bool.isRequired,
};
