import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    copyToClipBoardContainer: {
      width: "fit-content",
      position: "relative",
      fontSize: "16px",
      lineHeight: "24px",
      color: theme.palette.button.tertiary_fg,
      cursor: "pointer",
      padding: 0,
      margin: "0px !important",
      borderRadius: theme.borderRadius[4],
      direction: "ltr",
      display: "flex",
      alignItems: "center",
      fontWeight: 600,

      "&:hover": {
        color: theme.palette.button.tertiary_fg_hover,
        "& svg, & svg path": {
          fill: theme.palette.button.tertiary_fg_hover,
        },
      },
    },

    tooltip: {
      ...(isAr ? {
        minWidth: "88px",
        fontFamily: "Times New Roman", 
      } : {}),
      position: "absolute",
      top: "-38px", // Adjust this value to place the tooltip above the div
      left: 0,
      right: 0, // This will stretch the tooltip container to fill the width of the parent
      margin: "0 auto", // Centers the tooltip horizontally
      maxWidth: "fit-content", // Ensures the tooltip only takes up the necessary width
      backgroundColor: theme.palette.background.primary_solid,
      color: theme.palette.text.white,
      padding: "8px 12px",
      borderRadius: "8px",
      fontSize: "12px",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      zIndex: 1000,
      opacity: 0,
      transform: "translateY(20px)", // Start from below the final position
      transition: "opacity 0.3s ease, transform 0.3s ease", // Smooth transition for both opacity and transform
      visibility: "hidden", // Hidden by default
    },

    tooltipVisible: {
      visibility: "visible", // Make the tooltip visible
      opacity: 1,
      transform: "translateY(0)", // Move to the final position
      whiteSpace: "nowrap",
    },
  };
});
