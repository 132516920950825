import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { Spinner, Tables } from '../../../../../../Components';
import { TableActions , ScopeDocumentEnum } from '../../../../../../Enums';
import { getDownloadableLink, GetParams, getIsValidURL, getIsValidDataURL, showError  } from '../../../../../../Helper';
import { GetAllScopeDocuments } from '../../../../../../Services';
import { DeveloperProfileDocumentManagementDialog } from './Dialogs';
import { MediaPreviewDialog } from '../../../../TemplatesView/Dialogs';
import { DeleteDocumentDialog } from '../../../../UnitsView/UnitsProfileManagementView/Sections/UnitProfileDocumentsComponent/DeleteDocumentDialog';
import { useLocation } from "react-router-dom";
import { ShareDocumentsDialog } from '../../../../../../SharedComponents/ShareDocumentsDialog/ShareDocumentsDialog';

export const DeveloperDocumentsComponent = ({ parentTranslationPath, translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
    scopeTypeId : ScopeDocumentEnum.DeveloperDocument.scopeTypeId

  });
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [tableActions, setTableActions] = useState([])
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [response, setResponse] = useState({ result: [], totalCount: 0 });
  const [developerId, setDeveloperId] = useState(+GetParams('id') || null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenManagementDialog, setIsOpenManagementDialog] = useState(false);
  const [isOpenShareDialog, setIsOpenShareDialog] = useState(false);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const getDocumentByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllScopeDocuments({ ...filter, scopeId: developerId });
    if (!(result && result.status && result.status !== 200)) {
      setResponse({
        result: (result && result.result) || [],
        totalCount: (result && result.totalCount) || 0,
      });
    } else {
      setResponse({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [developerId, filter]);

  const openLink = (url)=>{
    if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
    if(getIsValidURL(url)){
        window.open(url || '');
    } else if(getIsValidDataURL(url)){
        let windowObj = window.open();
        const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
        windowObj.document.write(iframeElement);
    }
}

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex !== -1) {
      const defaultTableActions = getDefaultActionsWithPermissions();

      if(item && item.documentLink){
        setTableActions([...defaultTableActions, {
          enum: TableActions.openLinkText.key,
          isDisabled: false,
          externalComponent: null,
        }]);
      }else {
        setTableActions([...defaultTableActions, {
          enum: TableActions.openFile.key,
          isDisabled: false,
          externalComponent: null,
        },
        {
          enum: TableActions.downloadText.key,
          isDisabled: false,
          externalComponent: null,
        }
      ]);
      }
    }
  };

  const getDefaultActionsWithPermissions = () => {
    const list = [];
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
       list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
      list.push({
       enum: TableActions.share.key,
       isDisabled: false,
       externalComponent: null,
     });
    return list;
  };
  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem({
      templateFileId: item.documentId || item.uuid || item.fileId || null,
      templateFileName: item.documentName || item.fileName || item.fullfileName,
      templateText: '',
      scopeDocumentId: item.scopeDocumentId,
      documentName: item.documentName,
      documentId: item.documentLink,
      documentLink: item.documentLink,
      categoryId: item.categoryId,
      documentId: item.documentId || item.uuid || item.fileId || null,
    });


    if (actionEnum === TableActions.openFile.key)
    setIsOpenPreviewDialog(true);

    if (actionEnum === TableActions.openLinkText.key){
      try {
          openLink(item.documentLink)
      } catch (error) {
          showError(t(`${translationPath}this-link-is-not-valid`));
      }
      }

    if (actionEnum === TableActions.editText.key)
    setIsOpenManagementDialog(true);
    
    if (actionEnum === TableActions.deleteText.key)
    setIsOpenDeleteDialog(true);

    if (actionEnum === TableActions.share.key)
    setIsOpenShareDialog(true);
    
    if (actionEnum === TableActions.downloadText.key) {
      try {
        const link = document.createElement('a');
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', item.documentName);
        link.href = getDownloadableLink(item.documentId);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setActiveItem(null);
      } catch (error) { }
    }
  }, []);

  useEffect(() => {
    if (developerId) getDocumentByFormId();
  }, [getDocumentByFormId]);

  useEffect(() => {
    setDeveloperId(+GetParams('id'));
  }, [location]);

  useEffect(()=>{
    setTableActions(getDefaultActionsWithPermissions());
  }, [])

  return (
    <div className='associated-contacts-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}developer-documents`)}</span>
      </div>
      <div className='filter-section px-2 mb-3'>
        <div className='section'>
          <ButtonBase
            className='btns theme-solid px-3'
            onClick={() => {
              setIsOpenManagementDialog(true);
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-developer-document`)}
          </ButtonBase>
        </div>
      </div>
      <div className='w-100 px-2'>
        <Tables
          data={(response && response.result) || []}
          headerData={[
            {
              id: 1,
              label: t(`${translationPath}category`),
              input: 'categoryName'
            },
            {
              id: 2,
              label: t(`${translationPath}title`),
              component: (item) => (
                <span>
                  {item.documentName||item.documentLink}
                </span>
              ),
            },
            {
              id: 3,
              label: t(`${translationPath}remarks`),
              component: (item) => (
                <span>
                  {item.remarks||item.documentRemarks || ''}
                </span>
              ),
            },

            {
              id: 4,
              label: t(`${translationPath}created-date`),
              isDate: true,
              input: 'createdOn',
              dateFormat: 'DD/MM/YYYY',

            },
            {
              id: 5,
              label: t(`${translationPath}created-by`),
              input: 'createdBy',
            }]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={tableActions}
          focusedRowChanged={focusedRowChanged}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={response && response.totalCount}
        />
      </div>
      {isOpenManagementDialog && (
        <DeveloperProfileDocumentManagementDialog
        developerId={developerId}
          activeItem={activeItem}
          isOpen={isOpenManagementDialog}
          onSave={() => {
            setFilter((item) => ({ ...item, pageIndex: 0 }));
            setIsOpenManagementDialog(false);
            setActiveItem(null);
          }}
          isOpenChanged={() => {
            setIsOpenManagementDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isDeveloperDocuments
        />
      )}
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={activeItem}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath='Shared'
          translationPath={translationPath}
        />
      )}
      {isOpenDeleteDialog && (
        <DeleteDocumentDialog
          activeItem={activeItem}
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);
          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);
            setFilter((item) => ({ ...item, pageIndex: 0 }));
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {
        isOpenShareDialog &&
        <ShareDocumentsDialog
        open={isOpenShareDialog}
        activeItem={activeItem}
        onSave={() => {
          setIsOpenShareDialog(false);
        }}
        close={() => setIsOpenShareDialog(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        developerId={developerId}
        />
      }
    </div>
  );
};
DeveloperDocumentsComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
